
@font-face {
  font-family: 'Novarese';
  src: url('https://westminsterintranet.blob.core.windows.net/assets/fonts/NovareseBook.woff2') format('woff2'), url('https://westminsterintranet.blob.core.windows.net/assets/fonts/NovareseBook.woff') format('woff'), url('https://westminsterintranet.blob.core.windows.net/assets/fonts/NovareseBook.ttf') format('truetype'), url('https://westminsterintranet.blob.core.windows.net/assets/fonts/NovareseBook.svg#NovareseBook') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.novarese {
  font-family: 'Novarese' !important;
}

h1, h2 {
  font-family: 'Novarese' !important;
}

body {
  background: #fff;
}
.body-container{
  max-width:100%;
  width: 100%;
  overflow:hidden;
  position:absolute;
}

  header .content {
      overflow: hidden;
      height: 280px;
  }

  header img {
     height: 80px
  }

header::before {
  content: "";
  position: absolute;
  top: -100px;
  left: -10%;
  width: 120%;
  height: 400px;
  background-image: linear-gradient( 150deg,#00502f 15%,#239b48 70%,#239b48 94%);
  transform: rotate(-2deg);
  transform-origin: top;
}

.btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background-color: #00502f !important;
  border-color: #00502f !important;
  outline:none !important;
}



.checkmark__circle {
  stroke-dasharray: 216;
  /* ORIGINALLY 166px */
  stroke-dashoffset: 216;
  /* ORIGINALLY 166px */
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #00502f;
  fill: none;
  -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 106px;
  /* ORIGINALLY 56px */
  height: 106px;
  /* ORIGINALLY 56px */
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  -webkit-box-shadow: inset 0px 0px 0px #00502f;
  box-shadow: inset 0px 0px 0px #00502f;
  -webkit-animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  stroke-dasharray: 98;
  /* ORIGINALLY 48px */
  stroke-dashoffset: 98;
  /* ORIGINALLY 48px*/
  -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@-webkit-keyframes stroke {
  100% {
      stroke-dashoffset: 0;
  }
}

@keyframes stroke {
  100% {
      stroke-dashoffset: 0;
  }
}

@-webkit-keyframes scale {
  0%, 100% {
      -webkit-transform: none;
      transform: none;
  }

  50% {
      -webkit-transform: scale3d(1.1, 1.1, 1);
      transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes scale {
  0%, 100% {
      -webkit-transform: none;
      transform: none;
  }

  50% {
      -webkit-transform: scale3d(1.1, 1.1, 1);
      transform: scale3d(1.1, 1.1, 1);
  }
}

@-webkit-keyframes fill {
  100% {
      -webkit-box-shadow: inset 0px 0px 0px 80px #00502f;
      box-shadow: inset 0px 0px 0px 80px #00502f;
  }
}

@keyframes fill {
  100% {
      -webkit-box-shadow: inset 0px 0px 0px 80px #00502f;
      box-shadow: inset 0px 0px 0px 80px #00502f;
  }
}